/* @copyright (c) 2021-2022 Compular AB */
body {
  background: #f8f9fa;
}

#window {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#viewport {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
}

/* #contentArea {
  padding: 40px 40px 40px 72px;

  box-sizing: border-box;

  flex: 1;
  min-width: 600px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
} */

/* #nav-border {
  background-color: #dde2e5;
  height: 2px;
  width: 100%;
} */
