/* @copyright (c) 2021-2022 Compular AB */
#top-nav-wrapper {
  padding: 1.6% 2% 1.4%;
  display: flex;
  background-color: #fff;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  user-select: none;
}

#top-nav-wrapper .nav-text {
  display: block;
  margin-right: 30px;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #acb5bd;
  align-self: center;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  max-width: fit-content;
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

#top-nav-wrapper .nav-text.active {
  color: #212429;
}

#top-nav-text-wrapper {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  min-width: 0;
}

#top-nav-text-wrapper :last-child {
  margin-right: 0;
}

#progress-bar {
  display: flex;
}

#progress-bar .nav-text.active {
  color: #212429;
  margin-right: 16px;
}

#progress-bar svg {
  align-self: center;
}

