/* @copyright (c) 2021-2022 Compular AB */

.light-text {
   font-size: 16px; 
   font-weight: 400;
   line-height: 17px;
   font-family: Lato, sans-serif;
   font-style: normal;

   /* GRAY 8 */
   color: #505B65;
}