@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
#root {
  --toastify-spinner-color: #DA8437;
}
/* stylelint-disable property-no-vendor-prefix */
@layer components {
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
}

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  .h1-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
  }

  .h1-b {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }

  .h1-extra-b {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
  }

  .h2-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  .h2-b {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }

  .h2-extra-b {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
  }

  .h3-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
  }

  .h3-b {
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
  }

  .h3-extra-b {
    font-style: normal;
    font-weight: 800;
    font-size: 33px;
    line-height: 40px;
  }

  .h4-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
  }

  .h4-b {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
  }

  .h4-extra-b {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
  }

  .h5-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
  }

  .h5-b {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
  }

  .h5-extra-b {
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 28px;
  }

  .h6-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
  }

  .h6-b {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
  }

  .h6-extra-b {
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 23px;
  }

  .p-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .p-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .p-underline-b {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
  }

  .label-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .label-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }

  .label-cap {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .footer-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
  }

  .footer-semi-b {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
  }

  .footer-cap {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
  }

  .disabled-card {
    display: flex;
    flex-direction: col;
    justify-content: center;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 4px;
    box-shadow: 0 0 5px #c9cfd4;
  }

  .card {
    display: flex;
    flex-direction: col;
    justify-content: center;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 4px;
    box-shadow: 0 0 5px #c9cfd4;
    transform-style: preserve-3d;
    transition: 500ms;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0))
      translateY(var(--translate-y, 0));
  }

  .card:hover {
    --translate-y: -5px;
  }

  .card.flip {
    --rotate-y: 360deg;
  }

  .card.front {
    left: 0;
  }

  .card.front,
  .card.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .card .back {
    transform: rotateY(360deg);
  }
}

body {
  margin: 0;
  font-family: Lato, Roboto, 'Segoe UI', -apple-system, BlinkMacSystemFont,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

canvas {
  height: 100%;
}

h1 {
  font-size: 32px;
  font-weight: 600;
}

h2 {
  font-weight: 600;
  font-size: 28px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

/* Tippy theme primaryBlue */
.tippy-box[data-theme~='primaryBlue'] {
  background-color: #133859;
  color: white;
}

.tippy-box[data-theme~='primaryBlue'][data-placement^='top']
  > .tippy-arrow::before {
  border-top-color: #133859;
}

.tippy-box[data-theme~='primaryBlue'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: #133859;
}

.tippy-box[data-theme~='primaryBlue'][data-placement^='left']
  > .tippy-arrow::before {
  border-left-color: #133859;
}

.tippy-box[data-theme~='primaryBlue'][data-placement^='right']
  > .tippy-arrow::before {
  border-right-color: #133859;
}
