/* @copyright (c) 2021-2022 Compular AB */

.header-text {
    font-size: 40px; 
    font-weight: 600;
    line-height: 48px;
    font-family: Lato, sans-serif;
    font-style: normal;
 
    /* GRAY 9 */
    color: #282D32;
 }